import type { Constants } from './index';

const config: Constants = {
  isProduction: true,
  pinpointAppId: '243d698cd66f44e188739277a96c3bea',
  identityPoolId: 'ap-northeast-1:f55ccd01-f7a4-43d9-905f-46007c14804d',
  webAppFQDN: 'e-gift.anygift.jp',
  adminWebAppFQDN: 'admin.anygift.jp',
  apiEndpoint:
    'https://q2g7sv46jzbxljwitblgzewfzm.appsync-api.ap-northeast-1.amazonaws.com/graphql',
  platformApiEndpoint:
    'https://drk69awgqc.execute-api.ap-northeast-1.amazonaws.com',
  stripePublicKey:
    'pk_live_51MU255A1SC8BzvcwJ0pLCz7ZOjJKKFowgJxV7ufeb9K8PI03IX60U0DK9ro145GM9RrlFOvhPT2yaaePwqk74b0N00lwJwA1CD',
  stripeTestPublicKey:
    'pk_test_51MU255A1SC8Bzvcw78Llu0Tpg5bEJJ7TEXXk52SWfuscF7VVhUx5rYNPnlUZgciUFQ0HTRDlwJ38xa9EtYaa8XlR00r9kNq0aK',
  adminUserPoolId: '',
  adminUserPoolClientId: '',
  assetsDistributionUrl: 'https://d25rwisachr1q4.cloudfront.net',
  shopifyAppId: '6598327',
  ebisumartAppsCode: 'ANYREACH',
  ebisumartAuthHeader:
    'QU5ZUkVBQ0g6NWZiOWI0Y2E5MGNmNmU1MDRhYjQyZmE5M2QyODI3YjE=',
};

export default config;
